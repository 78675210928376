<template>
  <b-card>
    <p class="c0">
      <span class="c2" />
    </p>
    <ul class="c8 lst-kix_d0z1e7kt3b7e-0 start">
      <li class="c3 c6 c4 li-bullet-0">
        <span class="c7">Tambah Keluhan</span>
      </li>
    </ul>
    <p class="c3">
      <span class="c2">&nbsp;</span>
    </p>
    <p class="c3">
      <span class="c2">Untuk menambah data keluhan pengguna harus mengisi field di form tambah keluhan dengan lengkap</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 246.67px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image19.png')"
          style="width: 601.70px; height: 246.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />

      </span>
    </p>
    <p class="c3">
      <span class="c2">Setelah pengguna melengkapi semua field, maka pengguna dapat memilih tombol simpan, data keluhan akan muncul dalam tabel daftar keluhan seperti pada ilustrasi berikut.</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 76.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image1.png')"
          style="width: 601.70px; height: 76.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />

      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <ul class="c8 lst-kix_eimdw9swczjx-0 start">
      <li class="c3 c4 c6 li-bullet-0">
        <span class="c1">Edit Keluhan</span>
      </li>
    </ul>
    <p class="c3">
      <span>&nbsp;</span>
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 96.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image11.png')"
          style="width: 601.70px; height: 96.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />

      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">Merubah data keluhan hanya dapat dilakukan untuk Staff SE, dengan cara pilih tombol edit pada kolom aksi, selanjutnya akan mengarah pada detail keluhan yang dipilih sebagai berikut:</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 302.67px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image25.png')"
          style="width: 601.70px; height: 302.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />

      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">Selain pengguna dengan status staff SE maka tidak bisa edit data keluhan, dengan tampilan seperti berikut</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 293.33px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image2.png')"
          style="width: 601.70px; height: 293.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />

      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <ul class="c8 lst-kix_7weoqdu5kyvl-0 start">
      <li class="c3 c6 c4 li-bullet-0">
        <span class="c1">Komentar Keluhan</span>
      </li>
    </ul>
    <p class="c0">
      <span class="c1" />
    </p>
    <p class="c3">
      <span class="c2">Akun yang dipilih menjadi PIC dan responder memiliki akses berkomentar pada data keluhan seperti pada ilustrasi berikut.</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 152.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image20.png')"
          style="width: 601.70px; height: 152.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />

      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">Setelah keluhan selesai maka admin atau staff SE dapat memilih untuk menutup sesi keluhan melalui opsi berikut.</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <ul class="c8 lst-kix_i2feexisqodn-0 start">
      <li class="c3 c6 c4 li-bullet-0">
        <span class="c1">Tutup Keluhan</span>
      </li>
    </ul>
    <p class="c0">
      <span class="c1" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 173.33px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image13.png')"
          style="width: 601.70px; height: 173.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />

      </span>
    </p>
    <p class="c3">
      <span class="c2">Setelah itu pilih tombol simpan dan proses keluhan telah selesai</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
  </b-card>
</template>

<script>
import { BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
  },
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding-left: 0;
  li {
    span {
      font-size: 18px;
      font-weight: 700;
    }
  }
}
</style>
